<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Partners</h3>
       <router-link to="/admin-dashboard/partners">Details</router-link>
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
         <template #cell(summary)="row">       
        <button style="margin-left:5px;margin-top:5px;" class="btn btn-danger" @click="showDeleteModal(row.item.id, row.item.name)">X</button>
        <button class="ml-1 mt-1 btn purple-btn" @click="showModalForEdit(row.item)">Edit</button>
        <button class="btn purple-btn mt-1 ml-1" @click="showUsers(row.item.id, row.item.name)">Users</button>
         <Modal v-model="showModal" title="Delete Partner">
                <p>Are you sure you would like to delete this partner? <br><strong>{{nameToDelete}}</strong></p>
                <button @click="showModal = false" class="btn purple-btn"> &nbsp;&nbsp; Back</button>
                <button @click="deleteItem()" style="float:right;" class="btn btn-danger">Delete </button>              
        </Modal>
          <Modal v-model="showEditModal" title="Edit Partner">
                <div class="form-floating mb-2">
                    <input placeholder="name" type="text" v-model="partner.name" class="form-control" id="editpartner-name-input"/>
                    <label for="editpartner-name-input">Name:</label>
                </div>
                <div class="form-floating mb-2">
                    <input placeholder="address" type="text" v-model="partner.address" class="form-control" id="editpartner-address-input"/>
                    <label for="editpartner-address-input">Address:</label>
                </div>
                <div class="form-floating mb-2">
                    <input placeholder="city" type="text" v-model="partner.city" class="form-control" id="editpartner-city-input"/>
                    <label for="editpartner-address-input">City:</label>
                </div>
                <div class="form-floating mb-2">
                    <input placeholder="state" type="text" v-model="partner.state" class="form-control" id="editpartner-state-input"/>
                    <label for="editpartner-state-input">State:</label>
                </div>
                 <div class="form-floating mb-2">
                    <input placeholder="ZipCode" type="text" v-model="partner.zipCode" class="form-control" id="editpartner-zip-input"/>
                    <label for="editpartner-zip-input">ZipCode:</label>
                </div>
                <div class="form-floating mb-2">
                    <input placeholder="Type" type="text" v-model="partner.partnerType" class="form-control" id="editpartner-type-input"/>
                    <label for="editpartner-type-input">Partner Type:</label>
                </div>
                   <div class="form-floating mb-2">
                    <input placeholder="Contact Name" type="text" v-model="partner.contactName" class="form-control" id="editpartner-contactname-input"/>
                    <label for="editpartner-contactName-input">Contact Name:</label>
                </div>
                 <div class="form-floating mb-2">
                    <input placeholder="Contact Phone1" type="text" v-model="partner.contactPhone1" class="form-control" id="editpartner-contactphone1-input"/>
                    <label for="editpartner-contactphone1-input">Contact Phone 1:</label>
                </div>
                <div class="form-floating mb-2">
                    <input placeholder="Contact Phone2" type="text" v-model="partner.contactPhone2" class="form-control" id="editpartner-contactphone2-input"/>
                    <label for="editpartner-contactphone2-input">Contact Phone 1:</label>
                </div>
                 <div class="form-floating mb-2">
                    <input placeholder="Contact Email" type="text" v-model="partner.contactEmail" class="form-control" id="editpartner-contactemail-input"/>
                    <label for="editpartner-contactemail-input">Contact Email:</label>
                </div>
                   <div class="form-floating mb-2">
                    <select v-model="partner.hasLiabInsurance" class="form-control" id="editpartner-liab-input">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                    <label for="editpartner-liab-input">Has Liability Insurance:</label>
                </div>
                 <div class="form-floating mb-2">
                    <select v-model="partner.hasWorkCompInsurance" class="form-control" id="editpartner-hasworkerscompinsurance-input">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                    <label for="editpartner-hasworkerscompinsurance-input">Has Workers Comp Insurance:</label>
                </div>
                <div class="form-floating mb-2">
                    <select v-model="partner.hasWorkCompExemption" class="form-control" id="editpartner-hasworkerscompexemption-input">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                    <label for="editpartner-hasworkerscompexemption-input">Has Workers Comp Exemption:</label>
                </div>
                   <div class="form-floating mb-2">
                    <input placeholder="Certifications" type="text" v-model="partner.certifications" class="form-control" id="editpartner-certifications-input"/>
                    <label for="editpartner-certifications-input">Certifications:</label>
                </div>
                <div class="row">
                    <button class="col-md-4 offset-md-4 btn purple-btn" @click="saveExistingOrganization">Save</button>
                </div>

        </Modal>

      </template>
    </b-table>
    <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
              ></loading>
               </div>
    </div>
  </div>
</template>

<script>
import {adminService} from '../../_services/index'
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
     components:{
        'Modal':VueModal,
        Loading
    },
       mounted(){
        adminService.getAllPartnerOrganizations()
        .then((response) => {
            this.items = response.data;
             var totalItems = this.items.length;
            var pages = Math.ceil(totalItems / this.perPage);
            for(var i = 1; i <= pages; i++){
                this.pageNumbers.push(i);
            }
        })
    },
    data(){
        return{
            isLoading: false,
            showModal: false,
            showEditModal: false,
            fullPage: true,
            idToDelete: null,
            nameToDelete: null,
            items:[],
            partner:{ id: "", name: "", address: "", city: "", state: "", zipCode: "", partnerType: "",
             contactName: "", contactPhone1: "", contactPhone2: "", contactEmail: "", hasLiabInsurance: "false", hasWorkCompInsurance: "false", 
             hasWorkCompExemption: "false", certifications: ""},
            fields:[
                {
                    key: 'name', label: 'Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'regCode', label: 'Organization Code', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'partnerType', label: 'Partner Type', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'contactEmail', label: "Contact Email", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'createdOn', label: 'Created On', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'summary', label: ''
                }
            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
    methods:{
        setPage: function(number){
            this.currentPage = number;
        },
        showModalForEdit(row){
            this.partner = row;
            this.showEditModal = true;
        },
        showUsers(id, name){
            localStorage.setItem('OrganizationName', name)
            this.$router.push(`/admin-dashboard/partners/${id}/users`)
        },
        showDeleteModal(id, name){
            this.idToDelete = id;
            this.nameToDelete = name;
            this.showModal = true;
        },
        saveExistingOrganization(){
            this.partner.hasLiabInsurance = this.partner.hasLiabInsurance === "false" ? false : true;
            this.partner.hasWorkCompInsurance = this.partner.hasWorkCompInsurance === "false" ? false : true;
            this.partner.hasWorkCompExemption = this.partner.hasWorkCompExemption === "false" ? false : true;
            this.isLoading = true;
            adminService.editExistingPartner(this.partner)
            .then((response) => {
                this.isLoading = false;
                this.partner = response.data;
                 this.$toast.success("Successfully Saved Changes!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                this.isLoading = false;
                  this.$toast.error("Unable to save changes.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
            })
        },
         deleteItem(){
            adminService.deletePartnerOrganization(this.idToDelete)
            .then(() => {
                this.showModal = false;
                this.$toast.success("Successfully Deleted Partner!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                this.showModal = false;
                 this.$toast.error("Unable to delete partner organization.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
            })
        }
    }
}
</script>

<style>

</style>